import { useEffect } from "react";
import { useToasts } from "@puzzle/ui";
import {
  useCompanyFinancialSummaryQuery,
  CompanyFinancialMetricsStatus,
  useDailyCashQuery,
  useMonthlyBankBurnQuery,
} from "graphql/types";
import { useActiveCompany } from "components/companies";

export default function useCompanyFinancialSummary() {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const companyId = company.id;
  const showTopRowMetrics = !!company?.features.metricCardsCore;

  const { data, error, stopPolling, loading, called } = useCompanyFinancialSummaryQuery({
    context: { batch: false },
    variables: {
      companyId,
    },
    pollInterval: 5 * 1000,
    fetchPolicy: "cache-and-network",
  });

  const dailyCashResult = useDailyCashQuery({
    context: { batch: false },
    skip: !showTopRowMetrics,
    variables: {
      companyId,
    },
    pollInterval: 5 * 1000,
    fetchPolicy: "cache-and-network",
  });

  const monthlyBankBurnResult = useMonthlyBankBurnQuery({
    context: { batch: false },
    skip: !showTopRowMetrics,
    variables: {
      companyId,
    },
    pollInterval: 5 * 1000,
    fetchPolicy: "cache-and-network",
  });

  const { status, metrics } = data?.company?.financialSummary || {};

  useEffect(() => {
    if (error) {
      toast({ status: "error", message: "There was an error retrieving your metrics" });
    }
  }, [toast, error]);

  // General Metrics
  const ready = (() => {
    if (loading && !called) return false;
    if (!metrics) return false;
    if (status === CompanyFinancialMetricsStatus.Failed) return false;

    return true;
  })();

  useEffect(() => {
    if (ready) {
      stopPolling();
    }
  }, [ready, stopPolling]);

  // Daily Cash
  const readyDailyCash = (() => {
    const { status: dailyCashStatus, metrics: dailyCashMetrics } =
      dailyCashResult?.data?.company?.financialSummary || {};

    if (dailyCashResult.loading && !dailyCashResult.called) return false;
    if (!dailyCashMetrics) return false;
    if (dailyCashStatus === CompanyFinancialMetricsStatus.Failed) {
      return false;
    }

    return true;
  })();

  useEffect(() => {
    if (readyDailyCash) {
      dailyCashResult.stopPolling();
    }
  }, [readyDailyCash, dailyCashResult.stopPolling]);

  // Monthly Bank Burn
  const readyMonthlyBankBurn = (() => {
    const { status: MonthlyBankBurnStatus, metrics: MonthlyBankBurnMetrics } =
      dailyCashResult?.data?.company?.financialSummary || {};

    if (monthlyBankBurnResult.loading && !monthlyBankBurnResult.called) return false;
    if (!MonthlyBankBurnMetrics) return false;
    if (MonthlyBankBurnStatus === CompanyFinancialMetricsStatus.Failed) {
      return false;
    }

    return true;
  })();

  useEffect(() => {
    if (readyMonthlyBankBurn) {
      monthlyBankBurnResult.stopPolling();
    }
  }, [readyMonthlyBankBurn, monthlyBankBurnResult.stopPolling]);

  // Why? Some old accounts
  const ingesting = status === CompanyFinancialMetricsStatus.InProgress;

  return {
    ingesting,

    loading: !ready,
    ...metrics,

    dailyTotalCash: dailyCashResult.data?.company?.financialSummary?.metrics?.dailyTotalCash,

    monthlyBankBurn:
      monthlyBankBurnResult.data?.company?.financialSummary?.metrics?.monthlyBankBurn,
  };
}
