import React, { useMemo, useEffect } from "react";
import { usePreviousDistinct } from "react-use";
import { compact } from "lodash";
import Big from "big.js";
import { parseDate, today } from "@puzzle/utils";
import { useLocalStorage } from "react-use";
import { ChevronUp } from "@puzzle/icons";

import { styled } from "@puzzle/ui";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

import { Button, color, Stack } from "ve";

import { Collapse } from "ve";
import { TodaysCash, Burn, EstimatedRunway } from "../Metrics";
import { ReportsContainer } from "../Report";
import useCompanyFinancialSummary from "components/reports/useCompanyFinancialSummary";
import { BurnFormula } from "../Metrics/shared";
import { useAccountsForMetrics } from "components/reports/useAccountsForMetrics";
import { IntroTour } from "./IntroTour";
import { ChartTile } from "./ChartTile";
import { useStickyFormulas } from "./hooks";
import { useActiveCompany, PricingFeatures } from "components/companies";
import { Charts, Money } from "graphql/types";
import { useGetDashboardChartDataQuery } from "./ChartTile/graphql.generated";
import useCompanyArr from "../Revenue/useCompanyARR";
import { AccrualRevenueAlert } from "./AccrualRevenueAlert";
import { SpendGraphTile } from "../Metrics/SpendGraphTile";
import { RevenueGraphTile } from "../Metrics/RevenueGraphTile";
import { BurnComparisonTile } from "../Metrics/BurnComparisonTile";

type BurnFormulaDisplayValuesType = {
  lastMonthBurn?: Money;
  runwayLastMonth?: number | null;
  cashOutDateLastMonth?: string | null;
  threeMonthAverageBurn?: Money;
  runwayUsingThreeMonthAverage?: number | null;
  cashOutDateUsingThreeMonthAverage?: string | null;
};

const Cards = styled("div", {
  display: "grid",
  gridAutoFlow: "row",
  columnGap: "$2",
  rowGap: "$1h",

  "@media print": {
    display: "none",
  },

  variants: {
    graph: {
      true: {
        gridAutoRows: "230px",
      },
      false: {
        gridAutoRows: "100px",
      },
    },

    columnCount: {
      three: {
        gridTemplateColumns: "none",
        gridTemplateRows: `repeat(3, minmax(0, 1fr))`,
        "@l": {
          gridTemplateRows: `none`,
          gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        },
      },
      two: {
        gridTemplateColumns: "none",
        gridTemplateRows: `repeat(2, minmax(0, 1fr))`,
        "@l": {
          gridTemplateRows: `none`,
          gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
        },
      },
    },
  },
});

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

const StatCards = ({ charts, chartsLoading }: { charts?: Charts; chartsLoading: boolean }) => {
  const [collapsed, setCollapsed] = useLocalStorage("dashboard-metric-cards-collapsed", false);
  const {
    timeZone,
    initialIngestCompleted,
    pricingPlanFeaturesLoading,
    pricePlanFeatureEnabled,
    company,
  } = useActiveCompany<true>();

  // Top row graphs
  const isFirstRowGraphFeatureGated = // feature gate if both are true:
    !pricingPlanFeaturesLoading && // it is not loading (because we display a loading state)
    !pricePlanFeatureEnabled.has(PricingFeatures.metric_cards_core); // metric_cards_core is NOT enabled
  // Second row cards
  const isSecondRowFeatureGated = // feature gate if both are true:
    !pricingPlanFeaturesLoading && // it is not loading (because we display a loading state)
    !pricePlanFeatureEnabled.has(PricingFeatures.metric_cards_additional); // metric_cards_additional is NOT enabled

  // Burn comparison chart
  const isBurnComparisonChartEnabled = isPosthogFeatureFlagEnabled(
    FeatureFlag.DashboardChartBurnComparison
  );

  const {
    burnFormula,
    revenueFormula,
    showThreeMonthAverage,
    customBurnValue,
    setBurnFormula,
    setShowThreeMonthAverage,
    setCustomBurnValue,
  } = useStickyFormulas();
  const { loading, ingesting, dailyTotalCash, monthlyBankBurn, ...metrics } =
    useCompanyFinancialSummary();
  const { accounts } = useAccountsForMetrics();

  // Call this to preload the ARR for when the user goes to the Revenue Explorer page. Loading ARR
  // is slow, so Product wants us to preload and cache it. Don't bother polling though.
  useCompanyArr({ skipPolling: true });

  useEffect(() => {
    // unset the 3 month average selection when custom is selected
    if (burnFormula === BurnFormula.Custom) {
      setShowThreeMonthAverage(false);
    }
  }, [burnFormula, setShowThreeMonthAverage]);

  const formulas = useMemo(() => {
    const useCashGenerated = Big(
      showThreeMonthAverage
        ? metrics?.threeMonthAverageTotalBurn?.amount ?? 0
        : metrics?.lastMonthTotalBurn?.amount ?? 0
    ).gt(0);
    const useBankCashIncrease = Big(
      showThreeMonthAverage
        ? metrics?.threeMonthAverageBankBurn?.amount ?? 0
        : metrics?.lastMonthBankBurn?.amount ?? 0
    ).gt(0);

    return [
      {
        label: useCashGenerated ? "Cash Generated" : "Net Burn",
        value: BurnFormula.TotalBurn,
        description: useCashGenerated
          ? `Cash Generated is the sum of all cash activity excluding non-operating cash activity such as new investments or debt. The Cash Activity report provides the details of Cash Generated.`
          : `Net Burn is equal to monthly revenue minus monthly operating expenses, or gross burn. Net Burn provides a realistic view of long-term sustainability, but may obscure short-term cash flow with simultaneous fluctuations in revenues and expenses. The Cash Activity Report provides the details of net burn.`,
        pricingFeature: PricingFeatures.burn_metric_card_net_burn,
        isEstimated: true,
      },
      {
        label: useBankCashIncrease ? "Bank Cash Increase" : "Cash Burn",
        value: BurnFormula.BankBurn, // This was "Bank Burn" originally, leave ENUMs be since it's persisted to local storage
        description: useBankCashIncrease
          ? `Bank Cash Increase is the change in bank account balances between the beginning and end of a period.`
          : `Cash Burn is equal to the starting cash balance minus the ending cash balance for a given period. This is the simplest version of burn; it does not adjust for non-operating activity and does not consider credit card transactions until the credit card is paid.`,
        isEstimated: false,
      },
      {
        label: "Operating Income (proxy)",
        value: BurnFormula.AccrualOperatingIncomeBurn,
        description: (
          <Stack>
            <div>
              Operating income is equal to revenue mines the cost of revenue (COGS) minus operating
              expenses (OpEx).
            </div>
            <div>
              Operating Income can be a proxy for burn that reduces the impact of multi-month
              contract payments and irregular cash timing. This is an accrual-based metric that will
              not be be affected by the reporting on a cash basis in the income statement below.
            </div>
          </Stack>
        ),
        isEstimated: true,
      },
      {
        label: "Custom Burn",
        value: BurnFormula.Custom,
        description: "Set a hypothetical burn rate to simulate your runway.",
        pricingFeature: PricingFeatures.burn_metric_custom_burn,
        isEstimated: false,
      },
    ];
  }, [metrics, showThreeMonthAverage]);

  const customBurnDisplayValues: BurnFormulaDisplayValuesType = useMemo(() => {
    // bail early if we have invalid data
    if (!customBurnValue || customBurnValue === "0" || isNaN(Number(customBurnValue))) {
      return {
        lastMonthBurn: {
          currency: "USD",
          amount: "0",
        },
      };
    }

    const cashAmount = new Big(metrics?.totalCash?.amount ?? 0);
    const burnAmount = new Big(customBurnValue ?? 0);
    const runwayMonths = cashAmount.div(burnAmount).round(0, Big.roundDown);
    // 99+ year runway is effectively infinite
    const runwayIsInfinite = burnAmount.neg().gte(0) || runwayMonths.gte(12 * 99);

    const lastMonthBurn = {
      currency: "USD",
      amount: burnAmount.neg().toString(),
    };

    return {
      lastMonthBurn,
      runwayLastMonth: runwayIsInfinite ? undefined : runwayMonths.toNumber(),
      cashOutDateLastMonth: runwayIsInfinite
        ? undefined
        : today(timeZone).add({ months: runwayMonths.toNumber() }).toString(),
    };
  }, [customBurnValue, metrics?.totalCash?.amount, timeZone]);

  const forBurnFormula: BurnFormulaDisplayValuesType = useMemo(() => {
    switch (burnFormula) {
      case BurnFormula.Custom:
        return customBurnDisplayValues;
      case BurnFormula.AccrualOperatingIncomeBurn:
        return {
          lastMonthBurn: metrics.lastMonthAccrualOperatingIncomeBurn,
          threeMonthAverageBurn: metrics.threeMonthAverageAccrualOperatingIncomeBurn,

          runwayLastMonth: metrics.runwayUsingLastMonthAccrualOperatingIncomeBurn,
          runwayUsingThreeMonthAverage:
            metrics.runwayUsingThreeMonthAverageAccrualOperatingIncomeBurn,

          cashOutDateLastMonth: metrics.cashOutDateUsingLastMonthAccrualOperatingIncomeBurn,
          cashOutDateUsingThreeMonthAverage:
            metrics.cashOutDateUsingThreeMonthAverageAccrualOperatingIncomeBurn,
        };
      case BurnFormula.BankBurn:
        return {
          lastMonthBurn: metrics.lastMonthBankBurn,
          threeMonthAverageBurn: metrics.threeMonthAverageBankBurn,

          runwayLastMonth: metrics.runwayUsingLastMonthBankBurn,
          runwayUsingThreeMonthAverage: metrics.runwayUsingThreeMonthAverageBankBurn,

          cashOutDateLastMonth: metrics.cashOutDateUsingLastMonthBankBurn,
          cashOutDateUsingThreeMonthAverage: metrics.cashOutDateUsingThreeMonthAverageBankBurn,
        };
      case BurnFormula.TotalBurn:
        return {
          lastMonthBurn: metrics.lastMonthTotalBurn,
          threeMonthAverageBurn: metrics.threeMonthAverageTotalBurn,

          runwayLastMonth: metrics.runway,
          runwayUsingThreeMonthAverage: metrics.runwayUsingThreeMonthAverageTotalBurn,

          cashOutDateLastMonth: metrics.cashOutDate,
          cashOutDateUsingThreeMonthAverage: metrics.cashOutDateUsingThreeMonthAverageTotalBurn,
        };
      default:
        throw new Error(`Unhandled Burn Formula: ${burnFormula}`);
    }
  }, [metrics, burnFormula, customBurnDisplayValues]);

  const displayValues = useMemo(() => {
    const totalCash = metrics?.totalCash;
    if (showThreeMonthAverage) {
      return {
        burn: forBurnFormula.threeMonthAverageBurn,
        runway: forBurnFormula.runwayUsingThreeMonthAverage,
        cashOutDate: forBurnFormula.cashOutDateUsingThreeMonthAverage,
        totalCash,
      };
    }

    return {
      burn: forBurnFormula.lastMonthBurn,
      runway: forBurnFormula.runwayLastMonth,
      cashOutDate: forBurnFormula.cashOutDateLastMonth,
      totalCash,
    };
  }, [forBurnFormula, metrics.totalCash, showThreeMonthAverage]);

  const cards = useMemo(() => {
    const tileProps = {
      loading,
      ingesting,
    };

    return compact([
      <TodaysCash
        {...tileProps}
        totalCash={displayValues.totalCash}
        dailyTotalCash={dailyTotalCash?.metrics || []}
        accounts={accounts}
        key="cash"
        isGraphFeatureGated={isFirstRowGraphFeatureGated}
      />,
      <Burn
        {...tileProps}
        displayBurn={displayValues.burn}
        burnFormula={burnFormula}
        setBurnFormula={setBurnFormula}
        showThreeMonthAverage={showThreeMonthAverage}
        setShowThreeMonthAverage={setShowThreeMonthAverage}
        setCustomBurn={setCustomBurnValue}
        customBurnValue={customBurnValue}
        key="burn"
        formulas={formulas}
        charts={charts}
        monthlyBankBurn={monthlyBankBurn?.metrics}
        isGraphFeatureGated={isFirstRowGraphFeatureGated}
      />,
      <EstimatedRunway
        {...tileProps}
        runway={displayValues.runway ?? undefined}
        cashOutDate={displayValues.cashOutDate ? parseDate(displayValues.cashOutDate) : undefined}
        todaysCash={displayValues?.totalCash?.amount}
        key="runway"
        isGraphFeatureGated={isFirstRowGraphFeatureGated}
      />,
    ]);
  }, [
    accounts,
    displayValues,
    burnFormula,
    formulas,
    loading,
    showThreeMonthAverage,
    setBurnFormula,
    setShowThreeMonthAverage,
    customBurnValue,
    setCustomBurnValue,
    ingesting,
    isFirstRowGraphFeatureGated,
    charts,
    dailyTotalCash?.metrics,
    monthlyBankBurn?.metrics,
  ]);

  const previousBurnFormula = usePreviousDistinct(burnFormula);
  const previousDateView = usePreviousDistinct(showThreeMonthAverage);

  useEffect(() => {
    if (previousBurnFormula || previousDateView !== undefined) {
      Analytics.dashboardMetricCardFormulaChanged({
        card: "burn",
        formula: burnFormula,
        subFormula: showThreeMonthAverage ? "3MonthAverage" : "LastMonth",
      });
    }
  }, [burnFormula, showThreeMonthAverage, previousBurnFormula, previousDateView]);

  const previousRevenueFormula = usePreviousDistinct(revenueFormula);
  useEffect(() => {
    if (previousRevenueFormula) {
      Analytics.dashboardMetricCardFormulaChanged({
        card: "revenue",
        formula: revenueFormula,
      });
    }
  }, [previousRevenueFormula, revenueFormula]);

  const showGraph = !!company?.features.metricCardsCore;
  const showSecondRowTiles = !!company?.features.metricCardsAdditional;

  return (
    <>
      <Collapse
        open={showSecondRowTiles ? !collapsed : true} // Don't show collapse button unless second row used
        visibleOverflow={true}
        animate={true}
      >
        <Cards graph={showGraph ? "true" : "false"} columnCount={"three"}>
          {cards}
        </Cards>

        {showSecondRowTiles && (
          <Cards
            graph={"true"}
            columnCount={isBurnComparisonChartEnabled ? "three" : "two"}
            css={{ marginTop: "$2" }}
          >
            <SpendGraphTile
              data={charts?.spending}
              loading={chartsLoading}
              isFeatureGated={isSecondRowFeatureGated}
              initialIngestOngoing={!initialIngestCompleted}
            />
            {isBurnComparisonChartEnabled && (
              <BurnComparisonTile
                title="Net Burn MoM"
                alternativeTitle="Cash generated MoM"
                loading={chartsLoading}
                isFeatureGated={isSecondRowFeatureGated}
                initialIngestOngoing={!initialIngestCompleted}
                data={charts?.cashBalance}
              />
            )}
            <RevenueGraphTile
              title="Revenue"
              data={charts?.revenue}
              chartDatePointDataKey="revenue"
              loading={chartsLoading}
              isFeatureGated={isSecondRowFeatureGated}
              initialIngestOngoing={!initialIngestCompleted}
            />
          </Cards>
        )}
      </Collapse>
      {showSecondRowTiles && (
        <Button
          variant="outline"
          onClick={() => setCollapsed(!collapsed)}
          css={{
            alignSelf: "center",
            color: color.gray300, // TODO: is there another variant to be made here?
            borderColor: color.mauve600,
          }}
          suffixElement={<ChevronUp rotate={collapsed ? 180 : 0} />}
        >
          {collapsed ? "Expand metrics" : "Collapse metrics"}
        </Button>
      )}
    </>
  );
};

export const Dashboard = () => {
  const { company } = useActiveCompany<true>();

  const { data: dashboardChartData, loading: dashboardChartsLoading } =
    useGetDashboardChartDataQuery({
      variables: {
        companyId: company.id,
      },
      context: {
        batch: false,
      },
    });

  useEffect(() => {
    Analytics.dashboardViewed();
  }, []);

  const showSecondRowTiles = !!company?.features.metricCardsAdditional;
  const chartsLoading = !!dashboardChartsLoading && !dashboardChartData;

  return (
    <IntroTour>
      <Wrapper>
        <StatCards
          charts={dashboardChartData?.company?.charts || undefined}
          chartsLoading={chartsLoading}
        />

        {!showSecondRowTiles && (
          <ChartTile
            charts={dashboardChartData?.company?.charts || undefined}
            loading={chartsLoading}
          />
        )}
        <AccrualRevenueAlert />
        <ReportsContainer />
      </Wrapper>
    </IntroTour>
  );
};
