import React, { useEffect, useMemo, useRef } from "react";
import { useMachine } from "react-robot";
import dynamic from "next/dynamic";
import { createMachine, state, transition, action } from "robot3";

import Analytics from "lib/analytics";

import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";

import { useActiveCompany } from "components/companies";

import { PostOnboardModalV3 } from "../PostOnboardModalV3/PostOnboardModalV3";
import { usePostOnboardModalStore } from "../PostOnboardModalV3/postOnboardModalStore";
import { MembershipRole } from "graphql/types";
import type { TooltipTourProps } from "./TooltipTour";
import { useIntroTourStore } from "./introTourStore";
import { TOUR_ID } from "./shared";
import { SETTING_DASHBOARD_WELCOME_SEEN_KEY, useShowIntroTour } from "./useShowIntroTour";
import { useMoreAboutYouModalStore } from "components/common/MoreAboutYouModal/moreAboutYouModalStore";
import { usePOMChecklist } from "../PostOnboardModalV3/usePOMChecklist";

const DynamicTooltipTour = dynamic<TooltipTourProps>(() =>
  import("./TooltipTour").then((mod) => mod.TooltipTour)
);

type StateMachineContext = {
  membershipRole: `${MembershipRole}`;
};

const useIntroStateMachine = (enabled: boolean) => {
  const machine = useMemo(
    () =>
      createMachine(
        enabled ? "welcome" : "idle",
        {
          idle: state(transition("next", "welcome")),

          welcome: state(
            transition("next", "completed"),
            transition(
              "close",
              "completed",
              action(() => Analytics.tourClosed({ tourId: TOUR_ID }))
            )
          ),

          tooltips: state(transition("next", "completed")),

          completed: state(),
        },
        (initialContext: StateMachineContext) => ({
          membershipRole: initialContext.membershipRole,
        })
      ),
    [enabled]
  );

  const { membershipRole } = useActiveCompany<true>();
  return useMachine(machine, {
    membershipRole,
  });
};

type IntroTourContextType = {
  isInProgress: boolean;
  currentStep: ReturnType<typeof useIntroStateMachine>[0];
  send: ReturnType<typeof useIntroStateMachine>[1];
  initialIngestCompleted: boolean;
};

const IntroTourContext = React.createContext<IntroTourContextType | null>(null);

export const useIntroTourContext = () => {
  const context = React.useContext(IntroTourContext);
  if (context === null) {
    throw new Error("useIntroTourContext must be used as a child within ReconciliationProvider");
  }
  return context;
};

export const IntroTour = ({ children }: React.PropsWithChildren<unknown>) => {
  const startedLoadingTransactionsRef = useRef(false);
  const { loading: loadingTransactions } = usePOMChecklist();
  const isToolTipTourShown = useIntroTourStore((state) => state.isToolTipTourShown);
  const hideToolTipTour = useIntroTourStore((state) => state.hideToolTipTour);
  const { isPostOnboardModalShown, hidePostOnboardModal } = usePostOnboardModalStore();
  const { hideMoreAboutYouModal } = useMoreAboutYouModalStore();

  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();
  const { initialIngestCompleted, membership } = useActiveCompany<true>();
  const isOwner = useMemo(() => {
    return membership?.isOwner || false;
  }, [membership]);
  
  const { showTourOwner, showTourInvitee } = useShowIntroTour();
  const [currentStep, send] = useIntroStateMachine(showTourOwner || showTourInvitee);

  useEffect(() => {
    if (currentStep.name === "completed") {
      upsertMessageSettings({
        variables: {
          input: {
            key: SETTING_DASHBOARD_WELCOME_SEEN_KEY,
            value: true,
          },
        },
      });
      hideMoreAboutYouModal();
    }
  }, [currentStep.name, upsertMessageSettings]);

  useEffect(() => {
    if (currentStep.name !== "idle" && currentStep.name !== "completed") {
      if (currentStep.name === "welcome") {
        Analytics.tourViewed({ tourId: TOUR_ID });
      }
      Analytics.tourStepViewed({
        tourId: TOUR_ID,
        stepName: currentStep.name,
      });
    }
  }, [currentStep.name]);

  const contextValue = useMemo(
    () => ({
      isInProgress: currentStep.name !== "idle" && currentStep.name !== "completed",
      currentStep,
      send,
      initialIngestCompleted,
    }),
    [currentStep, initialIngestCompleted, send]
  );

  useEffect(() => {
    if(loadingTransactions) {
      startedLoadingTransactionsRef.current = true;
    }
  }, [loadingTransactions]);

  const loading = !startedLoadingTransactionsRef.current ? true : loadingTransactions
  const isWelcomeStep = currentStep.name === "welcome";
  const shouldShowPostOnBoardModal = isPostOnboardModalShown || (!loading && isWelcomeStep);

  return (
    <IntroTourContext.Provider value={contextValue}>
      {children}
      <>
        {shouldShowPostOnBoardModal && (
          <PostOnboardModalV3
            open={shouldShowPostOnBoardModal}
            onClose={() => {
              // Make sure this always gets done
              upsertMessageSettings({
                variables: {
                  input: {
                    key: SETTING_DASHBOARD_WELCOME_SEEN_KEY,
                    value: true,
                  },
                },
              });
              // The modal can be triggered by either mechanism, so make sure both are handled
              if(isWelcomeStep){
                send("next");
              }
              hidePostOnboardModal();

              hideMoreAboutYouModal();
            }}
            isInviteeVariant={!isOwner}
          />
        )}
        <DynamicTooltipTour
          run={currentStep.name === "tooltips" || isToolTipTourShown}
          callback={({ action, status, lifecycle, index, step }) => {
            if (
              (status === "finished" && lifecycle === "complete") ||
              action === "close" ||
              status === "skipped"
            ) {
              hideToolTipTour();
              if (currentStep.name === "tooltips") {
                send("next");
              }
            }
          }}
        />
      </>
    </IntroTourContext.Provider>
  );
};
