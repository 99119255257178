import React from "react";
import { useLocalStorage } from "react-use";

import { useActiveCompany } from "components/companies";
import useAppRouter from "lib/useAppRouter";
import { Alert, Text, Button } from "@puzzle/ui";
import { Exclamation } from "@puzzle/icons";
import Link from "components/common/Link";
import { IntegrationType } from "graphql/types";
import { Box, S } from "ve";

export const AccrualRevenueAlert = () => {
  const [showAlert, setShowAlert] = useLocalStorage("pz:enable-accrual-revenue", true);
  const { company, integrationConnections } = useActiveCompany<true>();
  const { goToIntegrations } = useAppRouter();
  const hasStripeConnection = Boolean(
    integrationConnections.find((integration) => integration.type === IntegrationType.Stripe)
  );

  if (!showAlert || company.features.accrualEventsEnabled === true || hasStripeConnection) {
    return null;
  }

  return (
    <Alert onClose={() => setShowAlert(false)} kind="neutral" icon={<Exclamation />}>
      <Box
        css={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: S["3"],
        }}
      >
        <Text variant="bodyS">
          Activate Accrual Revenue for Stripe to enable automatic accrual basis revenue recognition.{" "}
          <Link
            href="https://help.puzzle.io/en/collections/6284893-automated-revenue-recognition"
            target="_blank"
            color="gray200"
          >
            Learn more
          </Link>{" "}
          about this feature
        </Text>
        <Box css={{ marginLeft: "auto" }}>
          <Button size="mini" onClick={() => goToIntegrations()}>
            <Text>Activate</Text>
          </Button>
        </Box>
      </Box>
    </Alert>
  );
};
