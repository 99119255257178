import React, { useMemo } from "react";
import { DialogProps, Dialog } from "@puzzle/ui";
import { Clear } from "@puzzle/icons";

import { usePOMChecklist } from "./usePOMChecklist";
import { LeftPanel } from "./LeftPanel";
import { BookCall } from "./BookCall";
import { Categorize } from "./Categorize";
import { BookkeepPlan } from "./BookkeepPlan";
import { WhatsPosition } from "./WhatsPosition";
import { usePostOnboardModalStore } from "./postOnboardModalStore";

import { S, color, UnstyledButton } from "ve";

import {
  rootStyle,
  leftPanelStyle,
  rightPanelStyle,
} from "./PostOnboardModalV3.css";

import { PrepareYourFinancials } from "./PreparingYourFinancials";
import { SneakPeek } from "./SneakPeek";

export interface PostOnboardModalV3Props extends DialogProps {
  onClose: () => void;
  isInviteeVariant?: boolean;
}

export const PostOnboardModalV3 = ({
  onClose,
  isInviteeVariant = false,
  ...props
}: PostOnboardModalV3Props) => {
  const { checklistState, refetchMessageSettings } = usePOMChecklist();
  const { activePanel, setActivePanel } = usePostOnboardModalStore();

  const handleSetActivePanel = (val: number) => {
    setActivePanel(val);
  };

  const handdleCategorizeClose = async () => {
    await refetchMessageSettings();
    onClose();
  };

  const handdleCategorizeContinue = async () => {
    await refetchMessageSettings();
    setActivePanel(2);
  }

  const rightPanels = useMemo(() => {
    return isInviteeVariant
      ? [
          <WhatsPosition onContinue={() => setActivePanel(1)} key={0} />,
          ...(!checklistState.isCategorizationModelChecked ? [<Categorize onContinue={handdleCategorizeContinue} key={1} />] : []),
          <SneakPeek onClose={onClose} key={2} />,
        ]
      : [
          <PrepareYourFinancials onContinue={() => setActivePanel(1)} key={0}/>,
          <BookkeepPlan onContinue={() => setActivePanel(2)} key={1} />,
          <BookCall onContinue={() => setActivePanel(3)} key={2} />,
          <Categorize onClose={handdleCategorizeClose} key={3} />,
        ];
  }, [isInviteeVariant]);

  return (
    <Dialog width="900px" {...props}>
      <Dialog.Body
        css={{
          padding: "0",
          backgroundColor: "$mauve900",
          height: isInviteeVariant ? "598px" : "644px",
          position: "relative",
        }}
      >
        <UnstyledButton
          onClick={onClose}
          css={{ position: "absolute", right: S.$1, top: S.$1, padding: S.$1 }}
        >
          <Clear color={color.rhino200} size={24} />
        </UnstyledButton>
        <div className={rootStyle}>
          <div className={leftPanelStyle}>
            <LeftPanel
              setPanel={handleSetActivePanel}
              activeButtonIndex={activePanel}
              checklistState={checklistState}
              isInviteeVariant={isInviteeVariant}
            />
          </div>
          <div className={rightPanelStyle}>{rightPanels[activePanel]}</div>
        </div>
      </Dialog.Body>
    </Dialog>
  );
};
