import React from "react";

import { ChartDatePoint } from "graphql/types";
import { formatMoney } from "@puzzle/utils";

import Calculating from "./Calculating";
import { SpendWithCompositionBarGraph } from "./SpendBarGraph";
//import { chartDataPointPercentageDiff } from "./percentageDiffUtil"; // disable this for now
import LoadingMetrics from "./LoadingMetrics";

import { BasicGraphTileContainer } from "./BasicGraphTileContainer";

export const SpendGraphTile = ({
  data,
  loading,
  initialIngestOngoing,
  isFeatureGated,
}: {
  data?: ChartDatePoint[];
  loading?: boolean;
  initialIngestOngoing?: boolean;
  isFeatureGated?: boolean;
}) => {
  let formattedValue;
  let percentageIncrease;
  if (data && data.length >= 0) {
    const lastValue = data[data.length - 1]?.values.find((x) => x.key === "spending")?.value;
    formattedValue = formatMoney({ amount: parseFloat(lastValue || "0") }, { truncateValue: true });

    //percentageIncrease = chartDataPointPercentageDiff(data || [], "spending");
  }

  const startDate = (data && data.length >= 0 && data[0]?.date) || undefined;
  const endDate = (data && data.length >= 0 && data[data.length - 1]?.date) || undefined;

  return (
    <BasicGraphTileContainer
      title="Spend"
      valueString={formattedValue}
      //percentageIncrease={percentageIncrease}
      isFeatureGated={isFeatureGated}
      startDate={startDate}
      endDate={endDate}
    >
      {initialIngestOngoing ? (
        <Calculating css={{ marginLeft: "$2" }} />
      ) : loading ? (
        <div style={{ paddingLeft: "16px" }}>
          <LoadingMetrics />
        </div>
      ) : (
        <SpendWithCompositionBarGraph data={data} />
      )}
    </BasicGraphTileContainer>
  );
};
